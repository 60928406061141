import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Topbar from './Topbar';
import Navigation from './Navigation';
import MapComponent from './MapComponent';
import LoadingScreen from './LoadingScreen';
import { API_BASE_URL } from '../config';

function Home() {
  
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem('adventure_map_bearer_token');

  // Get user data on load
  useEffect(() => {
    
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }
    fetch(API_BASE_URL + 'user', requestOptions)
    .then(response => response.json())
    .then(response => {
      setUserData(response.user);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      navigate('/login');
    });

  }, []);

  if( loading !== false ) {
    return <LoadingScreen />
  }

  return (
      <div className="screen">
          {userData !== null && (
            <>
              <Topbar userData={userData} />
              <Navigation />
              <MapComponent userData={userData} />
            </>
          )}
      </div>
  );

}

export default Home;