import React from 'react';
import { useNavigate } from 'react-router-dom';

import AppHeader from './AppHeader';

function Topbar({ userData }) {    

    const navigate = useNavigate();

    return (
        <>
            {userData !== null && (
                <div className="topbar">
                    <div className="topbar_user_info">
                        <AppHeader />
                        <img src={userData.profile_image_url_64x64} alt="profile" onClick={() => navigate('/settings')} />
                    </div>
                </div>
            )}
        </>
    );
}

export default Topbar;