import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';


import BackButton from './BackButton';
import { API_BASE_URL } from '../config';


function SearchPage() {

    const navigate = useNavigate();

    const [searchDataPlaces, setSearchDataPlaces] = useState(null);
    const [searchDataUsers, setSearchDataUsers] = useState(null);
    const [searchDataEvents, setSearchDataEvents] = useState(null);

    const token = localStorage.getItem('adventure_map_bearer_token');

    useEffect(() => {

        // Get Initial search results
        if (searchDataUsers === null ) {

            fetch(API_BASE_URL + 'search/initial', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(response => {
                setSearchDataPlaces(response.data.searchable_data.places);
                setSearchDataEvents(response.data.searchable_data.events);
                setSearchDataUsers(response.data.searchable_data.users);
            })
            .catch(() => {
                
            });

        }

    }, []);

    return (
        <>
            <div className="screen scrollable content_top searchbar">
                <div className="screen-inner gap-normal">
                    <h2 className="section-title">Βρες αυτό που ψάχνεις</h2>
                    {searchDataPlaces && (
                        <>
                            <div className="search-results-section-title">
                                <span>Στέκια</span>
                            </div>
                            {searchDataPlaces.length > 0 ? (
                                <>
                                    <div className="search-results-list">
                                        {searchDataPlaces.map((item) => (
                                            <div key={item.id} className="list_item" onClick={() => navigate(`/profile/place/${item.nickname}`)}>
                                                {item.profile_image_url_64x64 &&
                                                    <div>
                                                        <img src={item.profile_image_url_64x64 } alt="" />
                                                    </div>
                                                }
                                                <div>
                                                    <div>{item.name}</div>
                                                    {item.description_short && <div>{item.description_short}</div>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="button search-results-find-more" onClick={() => navigate('/search/places')}>
                                        δες περισσότερα στέκια
                                    </button>
                                </>
                            ) : (
                                <div>Δεν βρέθηκαν στέκια</div>
                            )}
                        </>
                    )}
                    {searchDataEvents && (
                        <>
                            <div className="search-results-section-title">
                                <span>Events</span>
                            </div>
                            {searchDataEvents.length > 0 ? (
                                <>
                                    <div className="search-results-list">
                                        {searchDataEvents.map((item) => (
                                            <div key={item.id} className="list_item" onClick={() => navigate(`/event/${item.slug}`)}>
                                                {item.profile_image_url_64x64 &&
                                                    <div>
                                                        <img src={item.profile_image_url_64x64 } alt="" />
                                                    </div>
                                                }
                                                <div>
                                                    <div>{item.name}</div>
                                                    {item.description_short && <div>{item.description_short}</div>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="button search-results-find-more" onClick={() => navigate('/search/events')}>
                                        δες περισσότερα events
                                    </button>
                                </>
                            ) : (
                                <div>Δεν υπάρχουν ενεργά events τώρα - τσέκαρε αργότερα!</div>
                            )}
                        </>
                    )}
                    {searchDataUsers && (
                        <>
                            <div className="search-results-section-title">
                                <span>Άτομα</span>
                            </div>
                            {searchDataUsers.length > 0 ? (
                                <>
                                    <div className="search-results-list">
                                        {searchDataUsers.map((item) => (
                                            <div key={item.id} className="list_item" onClick={() => navigate(`/profile/user/${item.nickname}`)}>
                                                {item.profile_image_url_64x64 &&
                                                    <div>
                                                        <img src={item.profile_image_url_64x64 } alt="" />
                                                    </div>
                                                }
                                                <div>
                                                    <div>{item.name}</div>
                                                    {item.nickname && <div>@{item.nickname}</div>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="button search-results-find-more" onClick={() => navigate('/search/users')}>
                                        δες περισσότερους χρήστες
                                    </button>
                                </>
                            ) : (
                                <div>Δεν βρέθηκαν άτομα.</div>
                            )}
                        </>
                    )}
                    <div className="bottom-spacer"></div>
                </div>
            </div>
            <BackButton />
        </>
    )

}
export default SearchPage;