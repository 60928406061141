import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from './BackButton';
import { API_BASE_URL } from '../config';

function SearchPageSection() {

    const navigate = useNavigate();
    const { search_section } = useParams();

    const [searchSectionTitle, setSearchSectionTitle] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const [searchDataResults, setSearchDataResults] = useState(null);
    const [currentSearchResultsPage, setCurrentSearchResultsPage] = useState(1);
    const [lastSearchResultsPage, setLastSearchResultsPage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const fixedTopRef = useRef(null);

    const token = localStorage.getItem('adventure_map_bearer_token');
    const itemsListRef = useRef(null);

    useEffect(() => {
        if (fixedTopRef.current) {
            const fixedTopHeight = fixedTopRef.current.offsetHeight;
            const fixedTopSection = document.querySelector('.search-results-section');
            if (fixedTopSection) {
                fixedTopSection.style.height = `calc(100vh - ${fixedTopHeight}px - var(--el_space))`;
                fixedTopSection.style.top = `calc(${fixedTopHeight}px)`;
                fixedTopSection.style.left = `0`;
                fixedTopSection.style.transform = `none`;
            }
        }
    });

    useEffect(() => {

        // Set section title
        switch(search_section) {
            case 'users':
                setSearchSectionTitle('Άτομα');
                break;
            case 'events':
                setSearchSectionTitle('Events');
                break;
            case 'places':
                setSearchSectionTitle('Στέκια');
                break;
            default:
                setSearchSectionTitle('Στέκια, Events, Άτομα');
        }

        // Get Users search results
        if (searchData === null ) {

            fetch(API_BASE_URL + 'search/' + search_section + '?page=1', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(response => {
                setSearchData(response.data.searchable_data);
                setSearchDataResults(response.data.searchable_data);
                setLastSearchResultsPage(response.data.last_page);
            }).catch(() => {
                setErrorMessage('Δεν υπάρχει αυτό που αναζήτησες.');
            });

        }

    }, []);

    // Filter and show search results on user input
    const handleSearch = (e) => {
        let query = e.target.value;
        if (searchData !== null) {
            // filter places and users based on search query
            let filteredData = searchData.filter((item) => {
                if( item.name !== null && item.nickname !== null ) {
                    return item.name.toLowerCase().includes(query.toLowerCase()) ||
                    item.nickname.toLowerCase().includes(query.toLowerCase());
                } else { // filter only by nickname
                    return item.name.toLowerCase().includes(query.toLowerCase())
                }
            });
            setSearchDataResults(filteredData);
        }

    };

    // Get next 25 search results on page scroll
    const getNextSearchResults = (searchSection) => {
        let latestResultsPage = currentSearchResultsPage + 1;
        setCurrentSearchResultsPage(latestResultsPage);
        fetch(API_BASE_URL + 'search/' + searchSection + '?page=' + latestResultsPage, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(response => {
            setSearchData(prevSearchData => [...prevSearchData, ...response.data.searchable_data]);
        });
    }

    // On list scroll, when end is reached load next 25 results
    useEffect(() => {
        const handleScroll = () => {
          const container = itemsListRef.current;
          if (container) {
            const scrollPosition = container.scrollTop + container.clientHeight;
            const scrollHeight = container.scrollHeight;
            const isAtBottom = scrollPosition === scrollHeight;
    
            if (isAtBottom) {
              getNextSearchResults(search_section);
              console.log('bottom reached');
            }
          }
        };
    
        const container = itemsListRef.current;
        if (container) {
          container.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (container) {
            container.removeEventListener('scroll', handleScroll);
          }
        };
      }, [search_section, getNextSearchResults]);

    return (
        <>
            {errorMessage === null ? (
                <>
                    <form id="searchbar-form" className="searchform fixed_top" ref={fixedTopRef}>
                        <label>
                            <span>Βρες {searchSectionTitle}</span>
                            <input type="text" placeholder="πληκτρολόγησε εδώ..." onChange={handleSearch} />
                        </label>
                    </form>
                    <div className="screen content_top search-results-section">
                        {searchDataResults && (
                            searchDataResults.length > 0 ? (
                                <>
                                    <div className='items_list screen-inner gap-normal scrollable no-padding-block' ref={itemsListRef}>
                                        {searchDataResults.map((item) => (
                                            <div key={item.id} className="list_item" onClick={() => navigate(`/profile/${search_section}/${item.nickname}`)}>
                                                {item.profile_image_url_64x64 &&
                                                    <div>
                                                        <img src={item.profile_image_url_64x64 } alt="" />
                                                    </div>
                                                }
                                                <div>
                                                    <div>{item.name}</div>
                                                    {item.nickname && <div>@{item.nickname}</div>}
                                                    {item.description_short && <div>{item.description_short}</div>}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="bottom-spacer"></div>
                                        <div className="bottom-spacer"></div>
                                    </div>
                                </>
                            ) : (
                                <div className="no-search-results">Δεν βρήκαμε αποτελέσματα, ψάξε κάτι άλλο!</div>
                            )
                        )}
                    </div>
                </>
            ) : (
                <div className="no-search-results">{errorMessage}</div>
            )}
            <BackButton />
        </>
    )

}
export default SearchPageSection;