import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Register2 from './Register2';
import LoadingButton from './LoadingButton';
import IconView from './icons/IconView';
import IconViewHide from './icons/IconViewHide';
import { API_BASE_URL } from '../config';

function Register1() {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [continueToStep2, setContinueToStep2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);

  const isValidForm = () => {
    console.log((
      email && 
      isValidEmail(email) &&
      password.length >= 8 &&
      password === confirmPassword
    ));
    return (
      email && 
      isValidEmail(email) &&
      password.length >= 8 &&
      password === confirmPassword
    );
  };
  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(isValidForm) {
      setFormLoading(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password, password_repeat: confirmPassword })
      };
      fetch(API_BASE_URL + 'register/1', requestOptions)
        .then(response => {
          if (response.ok) {
            setContinueToStep2(true);
            return response.json();
          } else {
            return response.json().then(errorData => {
              setActionMessage(errorData.error);
            });
          }
        })
        .then(() => {
          setFormLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
          setFormLoading(false);
        });
    } else {
      setActionMessage('Συμπλήρωσε σωστά στοιχεία');
    }
  };

  return (
    <>
      {continueToStep2 ? (
        <Register2 data={{
          email: email,
          password: password,
        }} />
      ) : (
        // Register Step #1 component HTML
        <div className="user_form_container">
          {actionMessage ? (
            <div className="message positive" onClick={() => setActionMessage(null)}>{actionMessage}</div>
          ) : null}
          <form onSubmit={handleSubmit} className='user_form'>
            <label>
              <span>Email</span>
              <input 
                type="email" 
                value={email} 
                onChange={(event) => {
                  setEmail(event.target.value);
                  isValidForm();
                }} 
                placeholder=" " 
              />
            </label>
            <label>
              <span>Κωδικός πρόσβασης</span>
              <div className="input-container">
                <input 
                  type={showPassword ? 'text' : 'password'} 
                  value={password} onChange={(event) => {
                    setPassword(event.target.value);
                    isValidForm();
                  }} 
                  placeholder=" " 
                  className="password_input" 
                />
                <div className="view-password" onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}>
                  {showPassword ? (
                    <IconViewHide />
                  ) : (
                    <IconView />
                  )}
                </div>
              </div>
            </label>
            <label>
              <span>Επανάληψη κωδικού πρόσβασης</span>
              <div className="input-container">
                <input 
                  type={showPasswordConfirm ? 'text' : 'password'} 
                  value={confirmPassword} 
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                    isValidForm();
                  }} 
                  placeholder=" " 
                  className="password_input" 
                />
                <div 
                  className="view-password" 
                  onClick={() => showPasswordConfirm ? setShowPasswordConfirm(false) : setShowPasswordConfirm(true)}
                >
                  {showPasswordConfirm ? (
                    <IconViewHide />
                  ) : (
                    <IconView />
                  )}
                </div>
              </div>
            </label>
            {formLoading === true ? (
              <LoadingButton />
            ) : (
              <button type="submit" className="button_cta" disabled={!isValidForm()}>Πάρε κωδικό επιβεβαίωσης</button>
            )}
          </form>
          <button className="button_secondary fixed_down" onClick={() => navigate('/login')}>
            Έχεις ήδη λογαριασμό?
            <br></br>
            <u>Συνδέσου εδώ.</u>
          </button>
        </div>
      )}
    </>
  );
}

export default Register1;