function IconFriendAdd() {
    return (
        <div className="icon-friend-add">
            <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <circle cx="9" cy="7" fill="none" r="4" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="17" x2="23" y1="11" y2="11"/>
                <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="20" x2="20" y1="8" y2="14"/>
            </svg>
        </div>
    )
}
export default IconFriendAdd;