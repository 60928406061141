import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { DivIcon } from 'leaflet';

import IconLocation from './icons/IconLocation';
import IconCenter from './icons/IconCenter';
import LoadingScreen from './LoadingScreen';
import { API_BASE_URL } from '../config';

function MapComponent({ userData }) {

    const navigate = useNavigate();

    // Define a custom marker icon
    const mapMarkerIcon = new DivIcon({
        className: 'personal-marker',
        html: `<div class="marker-image"><img src="${userData.profile_image_url_32x32}" alt="Marker Image" /></div>`,
        iconSize: [32, 32],
        iconAnchor: [16, 52],
        popupAnchor: [0, -52]
    });

    const worldBounds = [
        [-90, -180], // Southwest coordinates of the world
        [90, 180],   // Northeast coordinates of the world
    ];

    const [locationActivateMessage, setLocationActivateMessage] = useState('Ενεργοποίησε την τοποθεσία σου για να χρησιμοποιήσεις την εφαρμογή!');

    // Set variables to be used
    const [loading, setLoading] = useState(true);
    
    // Find if user location is active
    const [locationLoading, setLocationLoading] = useState(null);
    
    // Broadcast location to friends
    const [locationBroadcasting, setLocationBroadcasting] = useState(false);

    // Watch player position
    const [locationWatchId, setLocationWatchId] = useState(null);
    const [playerPosition, setPlayerPosition] = useState(null);
    
    // Lat long used to initaly set the map center
    const [startingLat, setStartingLat] = useState(40.634314);
    const [startingLong, setStartingLong] = useState(22.942735);
    
    // Set friends locations
    const [friendPositions, setFriendPositions] = useState(null);

    // Set places locations
    const [placesPositions, setPlacesPositions] = useState(null);

    // Get stored bearer token
    const token = localStorage.getItem('adventure_map_bearer_token');

    // Get places positions. Only render once, they don't change
    useEffect(() => {

        // Get places positions
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        };
        fetch(API_BASE_URL + 'location/places', requestOptions)
        .then(response => response.json())
        .then(response => {
            setPlacesPositions(response.places);
        });

    }, []);

    // Render map buttons
    const MapButtons = () => {

        initiallyActivateLocation();

        // Recenter map button callback
        const map = useMap();
        const recenterMap = () => {
            if( playerPosition !== null ) {
                // If player position is already retrieved, go to it
                map.flyTo(playerPosition, map.getZoom());
            } else {
                // If player position is not retrieved yet, ask permission to locate them
                activateLocation();
            }
        };

        if (navigator.geolocation) {

            // Update location viewability in the database
            const locationBroadcastingToggle = () => {
                if( locationBroadcasting === true ) {
                    // Stop Broadcasting location
                    setLocationBroadcasting(false);
                    setFriendPositions(null);
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify({show_location: 0})
                    };
                    fetch(API_BASE_URL + 'location/toggle', requestOptions);
                } else {
                    // Broadcast location
                    setLocationBroadcasting(true);
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify({show_location: 1})
                    };
                    fetch(API_BASE_URL + 'location/toggle', requestOptions);
                }

            }

            return (
                <>
                    <div className="user-map-controls">
                        {playerPosition !== null && (
                            <div className={`map-button broadcast-location ${locationBroadcasting === true ? 'active' : ''}`}  onClick={locationBroadcastingToggle}>
                                <IconLocation />
                            </div>
                        )}
                        <div className={`map-button recenter-map ${playerPosition ? 'active' : 'pending'} ${locationLoading === true ? 'loading' : ''}`} onClick={recenterMap}>
                            <IconCenter />
                        </div>
                    </div>
                </>
            );
        } else {
            setLocationActivateMessage("Geolocation is not supported by this browser.");
        }

    }

    // Watch friends positions only if location is being broadcasted
    useEffect(() => {

        if ( ! locationBroadcasting) return;
        
        // Get friends positions and update your location
        const getFriendsPositionsCall = () => {
            // Get friends positions
            const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            };
            fetch(API_BASE_URL + 'location/friends', requestOptions)
            .then(response => response.json())
            .then(response => {
                setFriendPositions(response.data.friends);
                // Broadcast this player position
                const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    lat: playerPosition[0],
                    long: playerPosition[1]
                })
                };
                fetch(API_BASE_URL + 'location/update', requestOptions);
            });
        };
        
        // Call the function initially and set an interval to call it every 5 seconds
        getFriendsPositionsCall();
        const watchFriendsInterval = setInterval(getFriendsPositionsCall, 7000);
        
        // Stop watching friends locations and updating your location if broadcasting is off
        return () => {
            clearInterval(watchFriendsInterval);
        }

    }, [locationBroadcasting]);


    // Ask geolocation permission if not enabled
    const activateLocation = () => {
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
                if(locationLoading !== false) {
                    setLocationLoading(true);
                    navigator.geolocation.getCurrentPosition(function(position) {
                        loadMapPosition(position);
                        setPlayerPosition([position.coords.latitude, position.coords.longitude]);
                        setStartingLat(position.coords.latitude);
                        setStartingLong(position.coords.longitude);
                        setLocationLoading(false);
                    });
                }
            });
        } else {
            setLocationActivateMessage('Η αναγνώριση της τοποθεσίας σου δεν υποστηρίζεται από τη συσκευή σου.');
        }
    };


    const initiallyActivateLocation = () => {

        // Check navigator user given permissions
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
                if(permission.state === 'granted') {
                    if(locationLoading !== false) {
                        console.log('activating location init');
                        setLocationLoading(true);
                        navigator.geolocation.getCurrentPosition(function(position) {
                            console.log(position);
                            loadMapPosition(position);
                            setPlayerPosition([position.coords.latitude, position.coords.longitude]);
                            setStartingLat(position.coords.latitude);
                            setStartingLong(position.coords.longitude);
                            setLocationLoading(false);
                        });
                    }
                } else {
                    activateLocation();
                }
            });
        } else {
            setLocationActivateMessage('Η αναγνώριση της τοποθεσίας σου δεν υποστηρίζεται από τη συσκευή σου.');
        }
    };


    const loadMapPosition = (position) => {

        var lat = position.coords.latitude;
        var long = position.coords.longitude;
        setPlayerPosition([lat, long]); // update player position
        if( startingLat === null || startingLong === null ) {
            setStartingLat(lat); // update player starting position
            setStartingLong(long); // update player starting position
        }
        // Watch position as player moves
        navigator.permissions
            .query({ name: 'geolocation' })
            .then((permission) => {
                if( permission.state === 'granted' ) {
                    const id = navigator.geolocation.watchPosition(
                        position => {
                            var lat = position.coords.latitude;
                            var long = position.coords.longitude;
                            setPlayerPosition([lat, long]); // update player position
                            if( startingLat === null || startingLong === null ) {
                                setStartingLat(lat); // update player starting position
                                setStartingLong(long); // update player starting position
                            }
                    });
                    setLocationWatchId(id);
                } else {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        setPlayerPosition([position.coords.latitude, position.coords.longitude]);
                        setStartingLat(position.coords.latitude);
                        setStartingLong(position.coords.longitude);
                        const id = navigator.geolocation.watchPosition(
                            position => {
                                var lat = position.coords.latitude;
                                var long = position.coords.longitude;
                                setPlayerPosition([lat, long]); // update player position
                                if( startingLat === null || startingLong === null ) {
                                    setStartingLat(lat); // update player starting position
                                    setStartingLong(long); // update player starting position
                                }
                        });
                        setLocationWatchId(id);
                    });
                }
            })

    }

    useEffect(() => {
        setLoading(false);
    }, []);


    return (
        <>
            {loading === false ? (
                <>
                    <div className="map_container">
                        <MapContainer
                            center={[startingLat, startingLong]}
                            zoom={16} 
                            scrollWheelZoom={true}
                            bounds={worldBounds}
                            maxBoundsViscosity={1.0}
                        >
                            <TileLayer
                                attribution=""
                                url="https://stamen-tiles.a.ssl.fastly.net/toner-background/{z}/{x}/{y}.png"
                                maxZoom={16}
                                minZoom={9}
                            />
                            {playerPosition !== null && (
                                userData.profile_image_url !== undefined && (
                                    <Marker
                                    position={playerPosition}
                                    icon={mapMarkerIcon ? mapMarkerIcon : null}
                                    >
                                        <Popup>
                                            <div>
                                                <div>Η τοποθεσία σου</div>
                                                <img src={userData.profile_image_url_64x64} alt="" />
                                                <div className="marker-name">{userData.name}</div>
                                                <div className="user-marker-nickame">{userData.nickname}</div>
                                            </div>
                                        </Popup>
                                    </Marker>
                                )
                            )}
                            <MapButtons />
                            {friendPositions && 
                                friendPositions.length > 0 &&
                                    friendPositions.map((friend) => {
                                        console.log(friend);
                                        const friendIcon = new DivIcon({
                                            className: 'friend-marker',
                                            html: `<div class="marker-image">${friend.profile_image_thumbnail ? `<img src="${friend.profile_image_thumbnail}" alt="" />` : `<div class="marker-image-placeholder"></div>`}</div>`,
                                            iconSize: [32, 32],
                                            iconAnchor: [16, 52],
                                            popupAnchor: [0, -52]
                                        });
                                        return (
                                            <Marker 
                                                key={'friend_' + friend.user_id} 
                                                position={[friend.current_latitude, friend.current_longitude]} 
                                                icon={friendIcon}
                                            >
                                                <Popup>
                                                    <div onClick={() => navigate(`/profile/user/${friend.nickname}`)}>
                                                        <img src={friend.profile_image_url_64x64} alt="" />
                                                        <div className="marker-name">{friend.name}</div>
                                                        <div className="friend-marker-username">@{friend.nickname}</div>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        )
                                    })
                            }
                            {placesPositions && 
                                placesPositions.length > 0 &&
                                    placesPositions.map((place) => {
                                        const placeIcon = new DivIcon({
                                            className: 'place-marker',
                                            html: `<div class="marker-image">${place.profile_image_thumbnail ? `<img src="${place.profile_image_thumbnail}" alt="" />` : `<div class="marker-image-placeholder"></div>`}</div>`,
                                            iconSize: [32, 32],
                                            iconAnchor: [16, 52],
                                            popupAnchor: [0, -52]
                                        });
                                        return (
                                            <Marker 
                                                key={'place_' + place.id}
                                                position={[place.latitude, place.longitude]} 
                                                icon={placeIcon}
                                            >
                                                <Popup>
                                                    <div onClick={() => navigate(`/profile/place/${place.nickname}`)}>
                                                        <img src={place.profile_image_url_64x64} alt="" />
                                                        <div className="marker-name">{place.name}</div>
                                                        <div className="place-marker-description-short">{place.description_short}</div>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        )
                                    })
                            }
                        </MapContainer>
                    </div>
                </>
            ) : (
                <LoadingScreen />
            )}
        </>
    );
}

export default MapComponent;