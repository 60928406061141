import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import IconLogout from './icons/IconLogout';
import IconSettings from './icons/IconSettings';
import IconWallet from './icons/IconWallet';
import IconSearch from './icons/IconSearch';
import IconFriends from './icons/IconFriends';
import { API_BASE_URL } from '../config';

function Navigation() {

  const navigate = useNavigate();

  const [showUserActionPopup, setShowUserActionPopup] = useState(false);

  // Get stored bearer token
  const token = localStorage.getItem('adventure_map_bearer_token');

  // Log user out
  const logout = () => {
    if( token ) {
      console.log(token);
      fetch(API_BASE_URL + 'logout', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => response.json())
      .then(() => {
        localStorage.removeItem('adventure_map_bearer_token');
        navigate('/login');
      }).catch(error => { // If error, show it
        console.log(error);
      });
    }
  }

  return (
    <>
      <aside className="navigation">
        <button className="navigation-button" onClick={() => navigate('/settings')}>
          <IconSettings />
        </button>
        <button className="navigation-button" onClick={() => navigate('/wallet')}>
          <IconWallet />
        </button>
        <button className="navigation-button" onClick={() => navigate('/friends')}>
          <IconFriends />
        </button>
        <button className="navigation-button" onClick={() => navigate('/search')}>
          <IconSearch />   
        </button>
        <button className="navigation-button logout" onClick={() => setShowUserActionPopup(true)}>
          <IconLogout />
        </button>
      </aside>
      {showUserActionPopup === true && (
        <>
          <div className="popup-overlay" onClick={() => setShowUserActionPopup(false)}></div>
          <div className="popup">
            <div>
              <h2>Αποσύνδεση?</h2>
              <h3>Εμείς θα είμαστε εδώ όταν ξαναέρθεις!</h3>
              <div className="list_item_actions">
                <div className="button negative" onClick={() => logout()}>Αποσύνδεση</div>
                <div className="button positive" onClick={() => setShowUserActionPopup(false)}>Ακύρωση</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Navigation;
