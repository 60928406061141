import { useEffect, useState } from "react";
import BackButton from "./BackButton";
import { useNavigate } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import { API_BASE_URL } from "../config";

export default function WalletEvents() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [actionMessage, setActionMessage] = useState(null);

    const token = localStorage.getItem('adventure_map_bearer_token');

    useEffect(() => {
        fetch(API_BASE_URL + 'user/wallet/events', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if( response.ok ) {
                setEvents(response.json().data.events);
            } else {
                navigate('/login');
            }
        }).catch((error) => {
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
            setLoading(false);
        });
    }, [token]);

    if( loading !== false ) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className="screen content_top">
                <div className="screen-inner gap-normal">
                    <h2 className="section-title">Συμμετοχές σε events</h2>
                    {events.length === 0 ? (
                        <h3>Δεν έχεις δηλώσει συμμετοχή ακόμα</h3>
                    ) : (
                        <>
                            <h3>Έχεις δηλώσει συμμετοχή</h3>
                            <div className="items_list center">
                        
                            </div>
                        </>
                    )}
                </div>
                <BackButton />
            </div>
        </>
    );

}