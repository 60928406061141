import AppHeader from "./AppHeader";
import IconLoading from "./icons/IconLoading";

export default function LoadingScreen() {

    return (
        <div className="screen front loading-screen">
            <div>
                <AppHeader />
                <IconLoading />
            </div>
        </div>
    );

}