function IconLocation() {
    return (
        <svg width="32px" height="32px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <rect fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
                    <path d="M19,9.8 C19,13.7585 13.6628,18.581 12.3092,19.7405 C12.1299,19.894 11.8701,19.894 11.6908,19.7405 C10.3372,18.581 5,13.7585 5,9.8 C5,6.04446 8.13401,3 12,3 C15.866,3 19,6.04446 19,9.8 Z" id="Path" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path>
                    <circle id="Oval" stroke="var(--color-main)" strokeWidth="2" strokeLinecap="round" cx="12" cy="10" r="2"></circle>
                </g>
            </g>
        </svg>
    )
}
export default IconLocation;