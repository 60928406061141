import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IconEdit from './icons/IconEdit';
import IconUpload from './icons/IconUpload';
import IconReject from './icons/IconReject';
import BackButton from './BackButton';
import LoadingScreen from './LoadingScreen';
import IconSave from './icons/IconSave';
import PageInnerLoader from './PageInnerLoader';
import { API_BASE_URL } from '../config';

function Settings() {

    const navigate = useNavigate();

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [editProfileImage, setEditProfileImage] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [nameInitial, setNameInitial] = useState('');
    const [name, setName] = useState('');
    const [editName, setEditName] = useState(false);
    const nameMaxCharacters = 32;
    const [nicknameInitial, setNicknameInitial] = useState('');
    const [nickname, setNickname] = useState('');
    const [editNickname, setEditNickname] = useState(false);
    const nicknameMaxCharacters = 32;
    const [bioInitial, setBioInitial] = useState('');
    const [bio, setBio] = useState('');
    const [editBio, setEditBio] = useState(false);
    const bioMaxCharacters = 120;
    const [actionMessage, setActionMessage] = useState(null);

    const token = localStorage.getItem('adventure_map_bearer_token');

    const saveProfileImage = () => {
        setFormLoading(true);
        const formData = new FormData();
        formData.append('profile_image', profileImage);
        fetch( API_BASE_URL + 'user/update-meta/profile-image', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if( response.ok ) {
                setActionMessage('Η εικόνα προφίλ σου ανανεώθηκε επιτυχώς');
                fetchUserData();
                setEditProfileImage(false);
                setFormLoading(false);
            } else {
                return response.json().then(errorData => {
                    setActionMessage(errorData.error);
                    setFormLoading(false);
                });
            }
        })
        .catch(error => {
        console.error(error);
        setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
        setFormLoading(false);
        });
    }

    const saveName = () => {
        setFormLoading(true);
        fetch( API_BASE_URL + 'user/update-meta/name', {
            method: 'POST',
            body: JSON.stringify({
                name: name
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if( response.ok ) {
                setActionMessage('Το ονοματεπώνυμό σου ανανεώθηκε επιτυχώς');
                fetchUserData();
                setEditName(false);
                setFormLoading(false);
            } else {
                return response.json().then(errorData => {
                    setActionMessage(errorData.error);
                    setFormLoading(false);
                });
            }
        })
        .catch(error => {
        console.error(error);
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
            setFormLoading(false);
        });
    }

    const saveNickname = () => {
        setFormLoading(true);
        fetch( API_BASE_URL + 'user/update-meta/nickname', {
            method: 'POST',
            body: JSON.stringify({
                nickname: nickname
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if( response.ok ) {
                setActionMessage('Το ψευδώνυμό σου ανανεώθηκε επιτυχώς');
                fetchUserData();
                setEditNickname(false);
                setFormLoading(false);
            } else {
                return response.json().then(errorData => {
                    setActionMessage(errorData.error);
                    setFormLoading(false);
                });
            }
        })
        .catch(error => {
        console.error(error);
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
            setFormLoading(false);
        });
    }

    const saveBio = () => {
        setFormLoading(true);
        fetch( API_BASE_URL + 'user/update-meta/bio', {
            method: 'POST',
            body: JSON.stringify({
                bio: bio
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if( response.ok ) {
                setActionMessage('Το βιογραφικό σου ανανεώθηκε επιτυχώς');
                fetchUserData();
                setEditBio(false);
                setFormLoading(false);
            } else {
                return response.json().then(errorData => {
                    setActionMessage(errorData.error);
                    setFormLoading(false);
                });
            }
        })
        .catch(error => {
        console.error(error);
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
            setFormLoading(false);
        });
    }

    const fetchUserData = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }
        fetch(API_BASE_URL + 'user', requestOptions)
        .then(response => response.json())
        .then(response => {
            setUserData(response.data.user);
            setName(response.data.user.name);
            setNameInitial(response.data.user.name);
            setNickname(response.data.user.nickname);
            setNicknameInitial(response.data.user.nickname);
            setBio(response.data.user.bio);
            setBioInitial(response.data.user.bio);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchUserData();
    }, [token]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            {formLoading !== false &&
                <PageInnerLoader />
            }
            {actionMessage ? (
                <div className="message positive" onClick={() => setActionMessage(null)}>{actionMessage}</div>
            ) : null}
            <div className="screen scrollable content_top">
                <div className="screen-inner gap-normal">
                    <div className="items_list">
                        <h2 className="section-title">Ρυθμίσεις</h2>
                        <div className="account-settings">
                            <div className="account-settings-section account-settings-profile-image">
                                <h3>Εικόνα προφίλ</h3>
                                <div className="elements-container">
                                    {userData.profile_image_url !== null && editProfileImage === false ? ( 
                                        <>
                                            <img src={userData.profile_image_url} alt={userData.name} />
                                        </>
                                    ) : (
                                        <label>
                                            {profileImage ? (
                                                <div className="profile_image_container_settings">
                                                    <img src={URL.createObjectURL(profileImage)} alt="Profile" className="profile_image" />
                                                </div>
                                            ) : (
                                                <div className="input_with_image profile_image_container_settings">
                                                    <IconUpload />
                                                </div>
                                            )}
                                            <input 
                                                type="file" 
                                                accept="image/jpeg, image/png, image/heic, image/heif" 
                                                id="account-settings-upload-profile-image" 
                                                className="hidden_input" 
                                                onChange={(event) => setProfileImage(event.target.files[0])} 
                                            />
                                        </label>
                                    )}
                                    <div className="user-options">
                                        {editProfileImage === false ? (
                                            <div className="user-action-icon edit_button" onClick={() => setEditProfileImage(true)}>
                                                <IconEdit />
                                            </div>
                                        ) : (
                                            <>
                                                <div className="user-action-icon remove_button" onClick={() => {
                                                    setProfileImage(null);
                                                    setEditProfileImage(false);
                                                }}>
                                                    <IconReject />
                                                </div>
                                                {profileImage !== null &&
                                                    <div className="user-action-icon save_button" onClick={() => saveProfileImage()}>
                                                        <IconSave />
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="account-settings-section account-settings-name">
                                <h3>Ονοματεπώνυμο</h3>
                                {userData.name && (
                                    <>
                                        <div className="elements-container">
                                            <input
                                                type="text" 
                                                value={name}
                                                disabled={editName === false ? true : false}
                                                onChange={(event) => {
                                                    if(event.target.value.length <= nameMaxCharacters) {
                                                        setName(event.target.value.replace(/[\p{Emoji}]/gu, ''));
                                                    }
                                                }}
                                                placeholder=" "
                                            />
                                        </div>
                                        <div className="textarea_characters_counter">
                                            {name.length} / {nameMaxCharacters}
                                        </div>
                                    </>
                                )}
                                <div className="user-options horizontal">
                                    {editName === false ? (
                                        <div className="user-action-icon edit_icon" onClick={() => setEditName(true)}>
                                            <IconEdit />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="user-action-icon remove_button" onClick={() => {
                                                setEditName(false);
                                                setName(nameInitial);
                                            }}>
                                                <IconReject />
                                            </div>
                                            {name !== '' && name !== nameInitial &&
                                                <div className="user-action-icon save_button" onClick={() => saveName()}>
                                                    <IconSave />
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="account-settings-section account-settings-nickname">
                                <h3>Ψευδώνυμο</h3>
                                {userData.nickname && ( 
                                    <div className="elements-container">
                                        <input
                                            type="text" 
                                            value={nickname}
                                            disabled={editNickname === false ? true : false}
                                            onChange={(event) => {
                                                if(event.target.value.length < nicknameMaxCharacters) {
                                                    setNickname(event.target.value.replace(/[\p{Emoji}]/gu, ''));
                                                }
                                            }}
                                            placeholder=" "
                                        />
                                        <div className="textarea_characters_counter">
                                            {nickname.length} / {nicknameMaxCharacters}
                                        </div>
                                    </div>
                                )}
                                <div className="user-options horizontal">
                                    {editNickname === false ? (
                                        <div className="user-action-icon edit_icon" onClick={() => setEditNickname(true)}>
                                            <IconEdit />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="user-action-icon remove_button" onClick={() => {
                                                setEditNickname(false);
                                                setNickname(nicknameInitial);
                                            }}>
                                                <IconReject />
                                            </div>
                                            {nickname !== '' && nickname !== nicknameInitial &&
                                                <div className="user-action-icon save_button" onClick={() => saveNickname()}>
                                                    <IconSave />
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="account-settings-section account-settings-bio">
                                <h3>Bio</h3>
                                {userData.bio && ( 
                                    <div className="elements-container">
                                        <textarea 
                                            type="text" 
                                            value={bio}
                                            disabled={editBio === false ? true : false}
                                            onChange={(event) => {
                                                let bioLength = Array.from(bio).length;
                                                console.log(bioLength);
                                                if(bioLength < bioMaxCharacters) {
                                                    setBio(event.target.value);
                                                }
                                            }}
                                            placeholder=" "
                                        >
                                            {bio}
                                        </textarea>
                                        <div className="textarea_characters_counter">
                                            {Array.from(bio).length} / {bioMaxCharacters}
                                        </div>
                                    </div>
                                )}
                                <div className="user-options horizontal">
                                    {editBio === false ? (
                                        <div className="user-action-icon edit_icon" onClick={() => setEditBio(true)}>
                                            <IconEdit />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="user-action-icon remove_button" onClick={() => {
                                                setEditBio(false);
                                                setBio(bioInitial);
                                            }}>
                                                <IconReject />
                                            </div>
                                            {bio !== '' && bio !== bioInitial &&
                                                <div className="user-action-icon save_button" onClick={() => saveBio()}>
                                                    <IconSave />
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="secondary_button button_underlined" onClick={() => navigate('/privacy-policy')}>Πολιτική Απορρήτου</div>
                        {/* <div className="secondary_button button_underlined" onClick={() => navigate('/disconnect-all')}>Αποσύνδεση από όλες τις συσκευές</div> */}
                        <div className="secondary_button button_underlined" onClick={() => navigate('/delete-account')}>Διαγραφή λογαριασμού</div>
                    </div>
                    <div className="bottom-spacer"></div>
                </div>
            </div>
            <BackButton />
        </>
    );

}

export default Settings;