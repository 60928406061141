import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Home from './components/Home';
import Login from './components/Login';
import Register1 from './components/Register1';
import ResetPassword from './components/ResetPassword';
import ResetPasswordComplete from './components/ResetPasswordComplete';
import Friends from './components/Friends';
import Profile from './components/Profile';
import Settings from './components/Settings';
import Wallet from './components/Wallet';
import WalletCoupons from './components/WalletCoupons';
import WalletGiftcards from './components/WalletGiftcards';
import WalletEvents from './components/WalletEvents';
import SearchPage from './components/SearchPage';
import SearchPageSection from './components/SearchPageSection';
import NotFound from './components/NotFound';
import LoadingScreen from './components/LoadingScreen';
import PrivacyPolicy from './components/PrivacyPolicy';
import SettingsDeleteAccount from './components/SettingsDeleteAccount';
import { API_BASE_URL } from './config';

function App() {

  const [authorized, setAuthorized] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Get stored bearer token
  const token = localStorage.getItem('adventure_map_bearer_token');
  
  useEffect(() => {
  
    if (token) {
      
      fetch(API_BASE_URL + 'authorize', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => response.json())
      .then(data => {
        if( data.success === 1 ) { // If token valid, go to Home
          setAuthorized(true);
        } else {
          setAuthorized(false);
        }
        setLoading(false);
      }).catch(error => { // If error, show it
        setAuthorized(false);
        setLoading(false);
      });

    } else {
      setAuthorized(false);
      setLoading(false);
    }

  }, [token]);

  useEffect(() => {
    if (!loading && authorized === false) {
      navigate('/login');
    }
  }, [loading, authorized, navigate]);

  return (
    <>
      {(loading || authorized === null) &&
        <LoadingScreen />
      }
      <Routes>        
        <Route path="/" element={authorized ? <Home /> : <Login/>}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register1 />} />
        <Route path="/password-reset" element={<ResetPassword />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/wallet/coupons" element={<WalletCoupons />} />
        <Route path="/wallet/giftcards" element={<WalletGiftcards />} />
        <Route path="/wallet/events" element={<WalletEvents />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/search/:search_section" element={<SearchPageSection />} />
        <Route path="/search/:search_section/results" element={<SearchPageSection />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/profile/:profile_type/:profile_nickname" element={<Profile />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delete-account" element={<SettingsDeleteAccount />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
