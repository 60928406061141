import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IconUpload from './icons/IconUpload';
import { API_BASE_URL } from '../config';

function Register3(props) {

  const navigate = useNavigate();

  const [fullName, setFullName] = useState('');
  const [nickName, setNickname] = useState('');
  const [bio, setBio] = useState('');
  const bioMaxCharacters = 120;
  const [profileImage, setProfileImage] = useState(null);

  const [formLoading, setFormLoading] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormLoading(true);
    const formData = new FormData();
    formData.append('email', props.data.email);
    formData.append('password', props.data.password);
    formData.append('name', fullName);
    formData.append('nickname', nickName);
    formData.append('bio', bio);
    formData.append('profile_image', profileImage);
    fetch( API_BASE_URL + 'register/3', {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      if( response.ok ) {
        setActionMessage('Εγγράφηκες επιτυχώς, συνδέσου');
        navigate('/login');
      } else {
        return response.json().then(errorData => {
          setActionMessage(errorData.error);
          setFormLoading(false);
        });
      }
    })
    .catch(error => {
      console.error(error);
      setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
      setFormLoading(false);
    });
  };

  return (
    <>
      <div className="user_form_container">
        {actionMessage ? (
          <div className="message positive" onClick={() => setActionMessage(null)}>{actionMessage}</div>
        ) : null}
        <form onSubmit={handleSubmit} className='user_form'>
          <label>
            <span>Ονοματεπώνυμο</span>
            <input type="text" value={fullName} onChange={(event) => setFullName(event.target.value)} placeholder=" " />
          </label>
          <label>
            <span>Nickname (μόνο λατινικά + underscores)</span>
            <input type="text" value={nickName} onChange={(event) => setNickname(event.target.value)} placeholder=" " />
          </label>
          <label>
            <span>Bio</span>
            <textarea type="text" maxlength={bioMaxCharacters} value={bio} onChange={(event) => {
              if(event.target.value.length <= bioMaxCharacters) {
                setBio(event.target.value);
              }
            }} placeholder=" ">
              {bio}
            </textarea>
            <div className="textarea_characters_counter">
              {bio.length} / {bioMaxCharacters}
            </div>
          </label>
          <label>
            <span>Εικόνα προφίλ (JPG ή JEPG)</span>
            {profileImage ? (
              <div className="register_profile_image_container">
                <img src={URL.createObjectURL(profileImage)} alt="Profile" className="register_profile_image" />
              </div>
            ) : (
              <div className="input_with_image">
                <IconUpload />
              </div>
            )}
            <input type="file" accept="image/jpeg, image/png, image/heic, image/heif" onChange={(event) => setProfileImage(event.target.files[0])} placeholder=" " className="hidden_input" />
          </label>
          {formLoading !== true ? (
            <button type="submit" className='button_cta'>Εγγραφή</button>
          ) : (
            <div className="button_cta processing">
              Επεξεργασία....
            </div>
          )}
        </form>
        {formLoading === false && (
          <button className="button_secondary fixed_down" onClick={() => navigate('/login')}>
            Έχεις ήδη λογαριασμό?
            <br></br>
            <u>Συνδέσου εδώ.</u>
          </button>
        )}
      </div>
    </>
  );

}

export default Register3;