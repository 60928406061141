import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ResetPasswordComplete from './ResetPasswordComplete';
import LoadingButton from './LoadingButton';
import { API_BASE_URL } from '../config';

function ResetPassword() {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [continueToCompletion, setContinueToCompletion] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleSubmit = (event) => {

    event.preventDefault();

    setFormLoading(true);
    
    // Validate user data in the API
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email })
    };
    fetch(API_BASE_URL + 'reset/password', requestOptions)
    .then(response => {
      if( response.ok ) {
        setActionMessage('Στείλαμε κωδικό επιβεβαίωσης στο email σου');
        setCodeSent(true);
      } else {
        return response.json().then(errorData => {
          setActionMessage(errorData.error);
        });
      }
      setFormLoading(false);
    }).catch(error => {
      console.log(error);
      setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
      setFormLoading(false);
    });

  };

    return (
      <>
        {continueToCompletion ? (
          <ResetPasswordComplete data={{
            email: email
          }}/>
        ) : (
          <div className="user_form_container">
              {actionMessage ? (
                  <div className="message positive" onClick={() => setActionMessage(null)}>{actionMessage}</div>
              ) : null}
              <form onSubmit={handleSubmit} className='user_form'>
                  <label>
                      <span>Email</span>
                      <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder=" " />
                  </label>
                  {formLoading === false ? (
                      <>
                        <button type="submit" className='button_cta'>{codeSent === false ? ('Πάρε κωδικό επαναφοράς') : ('Επαναποστολή κωδικού')}</button>
                        {codeSent && 
                          <div className="button_secondary button_underlined" onClick={() => setContinueToCompletion(true)}>Συνέχεια</div>
                        }
                      </>
                  ) : (
                      <LoadingButton />
                  )}
              </form>
              <button className="button_secondary go_back" onClick={() => navigate('/login')}>
                  Πίσω
              </button>
          </div>
        )}
      </>
    );
}

export default ResetPassword;