import IconLoading from "./icons/IconLoading";

export default function LoadingScreenInner() {

    return (
        <div className="loading-screen-inner">
            <IconLoading />
        </div>
    );

}