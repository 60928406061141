import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingButton from './LoadingButton';
import { API_BASE_URL } from '../config';

function ResetPasswordComplete(props) {

    const navigate = useNavigate();

    const inputRefs = [
        null,
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef()
    ];

    const [verificationCode1, setVerificationCode1] = useState('');
    const [verificationCode2, setVerificationCode2] = useState('');
    const [verificationCode3, setVerificationCode3] = useState('');
    const [verificationCode4, setVerificationCode4] = useState('');
    const [verificationCode5, setVerificationCode5] = useState('');
    const [verificationCode6, setVerificationCode6] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [actionMessage, setActionMessage] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setFormLoading(true);
        const verificationCodeDigits = [
            verificationCode1,
            verificationCode2,
            verificationCode3,
            verificationCode4,
            verificationCode5,
            verificationCode6
        ];
        const verificationCode = verificationCodeDigits.join('');
        console.log(verificationCode);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                code: verificationCode, 
                email: props.data.email,
                password: password,
                password_repeat: passwordRepeat,
            })
        };
        fetch(API_BASE_URL + 'reset/password/complete', requestOptions)
        .then(response => {
            if (response.ok) {
                setActionMessage('Ο κωδικός πρόσβασης επαναφέρθηκε με επιτυχία, ξανασυνδέσου');
            } else {
                setActionMessage('Ο κωδικός επιβεβαίωσης δεν είναι σωστός');
            }
            setFormLoading(false);
        })
        .catch(error => {
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
            setFormLoading(false);
        });
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (value.length < 2) {
        // Update the corresponding state variable
        switch (index) {
            case 1:
                setVerificationCode1(value);
                break;
            case 2:
            setVerificationCode2(value);
                break;
            case 3:
                setVerificationCode3(value);
                break;
            case 4:
                setVerificationCode4(value);
                break;
            case 5:
                setVerificationCode5(value);
                break;
            case 6:
                setVerificationCode6(value);
                break;
            default:
                break;
        }

        // Move focus to the next input field if it exists
        if (index < inputRefs.length - 1) {
            console.log(index + ' ' + inputRefs.length);
            const nextInputRef = inputRefs[index + 1].current;
            nextInputRef.focus();
            nextInputRef.select();
        }

        isValidForm();
    }
  };

  const isValidForm = () => {
    return (
        verificationCode1 !== '' &&
        verificationCode2 !== '' &&
        verificationCode3 !== '' &&
        verificationCode4 !== '' &&
        verificationCode5 !== '' &&
        verificationCode6 !== '' &&
        password !== null && passwordRepeat !== null && password === passwordRepeat
    );
  };

    return (
        <>
            <div className="user_form_container">
                {actionMessage ? (
                    <div className="message positive" onClick={() => setActionMessage(null)}>{actionMessage}</div>
                ) : null}
                <form onSubmit={handleSubmit} className='user_form'>
                    <label>
                        <span>Βάλε τον 6ψήφιο κωδικό επιβεβαίωσης που στείλαμε στο email σου:</span>
                        <div className="inputs_special">
                            <input 
                                ref={inputRefs[1]}
                                type="number" 
                                value={verificationCode1} 
                                maxLength="1" 
                                onChange={(event) => handleInputChange(1, event)} 
                                placeholder=" "

                            />
                            <input 
                                ref={inputRefs[2]}
                                type="number" 
                                value={verificationCode2} 
                                maxLength="1" 
                                onChange={(event) => handleInputChange(2, event)} 
                                placeholder=" "

                            />
                            <input 
                                ref={inputRefs[3]}
                                type="number" 
                                value={verificationCode3} 
                                maxLength="1" 
                                onChange={(event) => handleInputChange(3, event)} 
                                placeholder=" "

                            />
                            <input 
                                ref={inputRefs[4]}
                                type="number" 
                                value={verificationCode4} 
                                maxLength="1" 
                                onChange={(event) => handleInputChange(4, event)} 
                                placeholder=" "

                            />
                            <input 
                                ref={inputRefs[5]}
                                type="number" 
                                value={verificationCode5} 
                                maxLength="1" 
                                onChange={(event) => handleInputChange(5, event)} 
                                placeholder=" "

                            />
                            <input 
                                ref={inputRefs[6]}
                                type="number" 
                                value={verificationCode6} 
                                maxLength="1" 
                                onChange={(event) => handleInputChange(6, event)} 
                                placeholder=" " />
                        </div>
                    </label>
                    <label>
                        <span>Νέος κωδικός πρόσβασης</span>
                        <input type="password" value={password} onChange={(event) => {
                            setPassword(event.target.value);
                            isValidForm();
                        }} placeholder=" " />
                    </label>
                    <label>
                        <span>Επανάληψη νέου κωδικού πρόσβασης</span>
                        <input type="password" value={passwordRepeat} onChange={(event) => {
                            setPasswordRepeat(event.target.value);
                            isValidForm();
                        }} placeholder=" " />
                    </label>
                    {formLoading === true ? (
                        <LoadingButton />
                    ) : (
                        <>
                            <button type="submit" className='button_cta' disabled={!isValidForm()}>Επαναφορά κωδικού</button>
                        </>
                    )}
                </form>
                <button className="button_secondary fixed_down" onClick={() => navigate('/login')}>
                    Έχεις ήδη λογαριασμό?
                    <br></br>
                    <u>Συνδέσου εδώ.</u>
                </button>
            </div>
        </>
    );
}

export default ResetPasswordComplete;