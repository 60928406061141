import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

import LoadingButton from './LoadingButton';

function Login() {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [])

  const handleSubmit = (event) => {

    event.preventDefault();
    setFormLoading(true);
    // Validate user data in the API
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        email: email, 
        password: password 
      })
    };
    console.log(API_BASE_URL);
    fetch(API_BASE_URL + 'login', requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json(); // Return the response JSON promise
      } else {
        setActionMessage('Συμπλήρωσε σωστά στοιχεία');
        throw new Error('Wrong credentials');
      }
    })
    .then(responseJson => {
      localStorage.setItem('adventure_map_bearer_token', responseJson.token);
      setActionMessage('Συνδέθηκες επιτυχώς');
      setTimeout(function () {
        window.location.href = '/home';
      }, 1000);
    })
    .catch(error => {
      console.log(error);
      setActionMessage('Συμπλήρωσε σωστά στοιχεία');
      setFormLoading(false);
    });

  };

  const isValidForm = () => {
    return (
      email && 
      isValidEmail(email) &&
      password.length >= 8
    );
  };
  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  if (loading !== true) {
    return <div className="loading-screen">Φορτώνουμε την εφαρμογή...</div>;
  }

  return (
    <div className="user_form_container">
      {actionMessage ? (
        <div className="message positive">{actionMessage}</div>
      ) : null}
      <form onSubmit={handleSubmit} className='user_form'>
          <label>
              <span>Email</span>
              <input type="email" value={email} onChange={(event) => {
                setEmail(event.target.value);
                isValidForm();
              }} placeholder=" " />
          </label>
          <label>
              <span>Κωδικός πρόσβασης</span>
              <input type="password" value={password} onChange={(event) => {
                setPassword(event.target.value);
                isValidForm();
              }} placeholder=" " />
          </label>
          {formLoading === false ? (
            <button type="submit" className='button_cta' disabled={!isValidForm()}>Σύνδεση</button>
          ) : (
            <LoadingButton />
          )}
      </form>
      <div className="button_secondary" onClick={() => navigate('/password-reset')}>Ξέχασα τον κωδικό πρόσβασης</div>
      <button className="button_secondary fixed_down" onClick={() => navigate('/register')}>
        Θέλεις λογαριασμό?
        <br></br>
        <u>Εγγράψου τώρα δωρεάν.</u>
      </button>
    </div>
  );
}

export default Login;