import BackButton from "./BackButton";

export default function PrivacyPolicy() {

    return (
        <>
            <div className="screen content_top scrollable">
                <div className="screen-inner">
                    <h1>Πολιτική Απορρήτου</h1>

                    <p>Η παρούσα πολιτική απορρήτου περιγράφει τον τρόπο με τον οποίο συλλέγουμε, χρησιμοποιούμε και προστατεύουμε τα προσωπικά σας δεδομένα όταν χρησιμοποιείτε την εφαρμογή μας. Παρακαλούμε διαβάστε προσεκτικά αυτήν την πολιτική και επικοινωνήστε μαζί μας αν έχετε οποιεσδήποτε ερωτήσεις ή ανησυχίες σχετικά με την επεξεργασία των προσωπικών σας δεδομένων.</p>

                    <h2>1. Συλλογή και Χρήση Δεδομένων</h2>

                    <p>Κατά τη χρήση της εφαρμογής μας, μπορεί να συλλέγονται ορισμένα προσωπικά δεδομένα που μας παρέχετε εθελοντικά, όπως η διεύθυνση email, ο κωδικός πρόσβασης, η εικόνα προφίλ, το όνομα, το ψευδώνυμο, η βιογραφία και άλλες πληροφορίες που επιλέγετε να μοιραστείτε. Τα προσωπικά δεδομένα που συλλέγονται χρησιμοποιούνται για τους εξής σκοπούς:</p>

                    <p>- Εγγραφή και διαχείριση λογαριασμού: Χρησιμοποιούμε τα προσωπικά σας δεδομένα για να σας εγγράψουμε και να διαχειριστούμε το λογαριασμό σας, συμπεριλαμβανομένης της διαχείρισης ασφαλείας, των πληροφοριών επαναφοράς κωδικού πρόσβασης και των αλλαγών στα προσωπικά σας στοιχεία.</p>

                    <p>- Αναζήτηση: Στην εφαρμογή μας, μπορείτε να πραγματοποιήσετε αναζήτηση για στέκια, events και άλλους χρήστες. Τα προσωπικά σας δεδομένα μπορεί να χρησιμοποιηθούν για την εμφάνιση των αποτελεσμάτων αναζήτησης και τη διευκόλυνση της επικοινωνίας με άλλους χρήστες.</p>

                    <p>- Προσωπικό Πορτοφόλι: Το προσωπικό σας πορτοφόλι περιλαμβάνει τα αγορασμένα κουπόνια, τις αγορασμένες κάρτες δώρων και τα εισιτήρια για events. Τα προσωπικά σας δεδομένα μπορεί να χρησιμοποιηθούν για τη διαχείριση και την εμφάνιση αυτών των στοιχείων.</p>

                    <p>- Φίλοι: Στην εφαρμογή μας, μπορείτε να αποστείλετε αιτήματα φιλίας, να αποκλείσετε χρήστες και να διαχειριστείτε τη λίστα των φίλων σας. Τα προσωπικά σας δεδομένα μπορεί να χρησιμοποιηθούν για την επικοινωνία και τη διαχείριση των φίλων σας.</p>

                    <p>- Τοποθεσία: Η εφαρμογή μας μπορεί να παρακολουθεί την προσωπική σας τοποθεσία μόνο στην πλευρά του προγράμματος περιήγησης με τη χρήση του JavaScript. Επιπλέον, μπορείτε να μεταδώσετε την τοποθεσία σας στους φίλους σας, εφόσον συμφωνήσετε πατώντας το κουμπί μετάδοσης τοποθεσίας. Οι πληροφορίες τοποθεσίας που μεταδίδονται αποθηκεύονται προσωρινά στη βάση δεδομένων και διανέμονται στους φίλους που έχουν ανοιχτή τη μετάδοση τοποθεσίας τους. Η τοποθεσία δεν κοινοποιείται σε χρήστες που δεν είναι φίλοι σας ή δεν έχουν ανοιχτή τη μετάδοση τοποθεσίας τους. Οι πληροφορίες τοποθεσίας διαγράφονται μετά από 30 δευτερόλεπτα αδράνειας ή όταν κλείσετε τη μετάδοση τοποθεσίας.</p>

                    <p>- Κουπόνια: Ο χρήστης μπορεί να αγοράσει κουπόνια και να τα προβάλλει στο προσωπικό του πορτοφόλι. Επίσης, μπορεί να επιστρέψει οποιοδήποτε αντικείμενο είναι επιστρέψιμο (αυτό καθορίζεται από τον ιδιοκτήτη του εκάστοτε καταστήματος).</p>

                    <p>- Κάρτες Δώρου: Ο χρήστης μπορεί να εκδόσει δωροκάρτες και να τις προβάλλει στο προσωπικό του πορτοφόλι. Επίσης, μπορεί να επιστρέψει οποιοδήποτε αντικείμενο είναι επιστρέψιμο (αυτό καθορίζεται από τον ιδιοκτήτη του εκάστοτε καταστήματος).</p>

                    <p>- Events: Ο χρήστης μπορεί να εκδηλώσει ενδιαφέρον για events και να τα προβάλλει στο προσωπικό του πορτοφόλι. Επίσης, μπορεί να αγοράσει εισιτήρια για events και να τα προβάλλει στο προσωπικό του πορτοφόλι. Ο χρήστης μπορεί να επιστρέψει οποιοδήποτε αντικείμενο είναι επιστρέψιμο (αυτό καθορίζεται από τον διοργανωτή του εκάστοτε event).</p>

                    <p>- Αγγελίες Εργασίας: Ο χρήστης μπορεί να εκδηλώσει ενδιαφέρον για αγγελίες εργασίας και να τις προβάλλει στο προσωπικό του πορτοφόλι. Επίσης, μπορεί να δει την κατάσταση των αγγελιών εργασίας ή να τις αφαιρέσει από το πορτοφόλι του.</p>

                    <p>Επιπλέον, τα προσωπικά σας δεδομένα μπορεί να αποθηκεύονται ως εγγραφές στη βάση δεδομένων και να χρησιμοποιούνται για την παραγωγή στατιστικών. Οι ιδιοκτήτες καταστημάτων δεν έχουν πρόσβαση στις προσωπικές σας πληροφορίες, όπως στο email και στο ονοματεπώνυμό σας.</p>

                    <h2>2. Κοινοποίηση Δεδομένων</h2>

                    <p>Τα προσωπικά σας δεδομένα μπορεί να κοινοποιηθούν στους φίλους σας μόνο εάν έχετε ανοιχτή τη λειτουργία μετάδοσης τοποθεσίας και έχετε συμφωνήσει να μοιραστείτε την τοποθεσία σας. Επίσης, τα δεδομένα σας δεν κοινοποιούνται σε χρήστες που δεν είναι φίλοι σας ή δεν έχουν ανοιχτή τη λειτουργία μετάδοσης τοποθεσίας τους.</p>

                    <h2>3. Ασφάλεια Δεδομένων</h2>

                    <p>Στην εφαρμογή μας, λαμβάνουμε μέτρα ασφαλείας για την προστασία των προσωπικών σας δεδομένων. Αυτά περιλαμβάνουν την κρυπτογράφηση των δεδομένων σας, την χρήση ασφαλούς σύνδεσης (HTTPS) κατά την ανταλλαγή δεδομένων και την εφαρμογή πολιτικών ασφαλείας και προστασίας πληροφοριών.</p>

                    <h2>4. Διαγραφή Λογαριασμού</h2>

                    <p>Μπορείτε να διαγράψετε τον λογαριασμό σας από την εφαρμογή. Αυτό θα οδηγήσει στην διαγραφή όλων των προσωπικών σας δεδομένων που αποθηκεύονται στη βάση δεδομένων μας. Αυτή η πράξη είναι μη αναστρέψιμη.</p>

                    <h2>5. Αποσύνδεση και Αλλαγή Κωδικού Πρόσβασης</h2>

                    <p>Μπορείτε να αποσυνδεθείτε από την εφαρμογή για να τερματίσετε την τρέχουσα συνεδρία σας. Επίσης, μπορείτε να αλλάξετε τον κωδικό πρόσβασης σας, ακολουθώντας τις αντίστοιχες διαδικασίες ασφαλείας, όπως η αποστολή ενός 6ψήφιου κωδικού επαλήθευσης στο email σας.</p>

                    <h2>6. Καταγραφή Συσκευών</h2>

                    <p>Μπορείτε να καταγράψετε όλες τις συνδεδεμένες συσκευές στον λογαριασμό σας και να τις αποσυνδέσετε από την εφαρμογή. Για να γίνει αυτό, θα πρέπει να επαληθεύσετε την ταυτότητά σας με την υποβολή ενός 6ψήφιου κωδικού που θα σταλεί στο email που χρησιμοποιήθηκε για τη δημιουργία του λογαριασμού σας.</p>
                    <div className="bottom-spacer"></div>
                </div>
            </div>
            <BackButton />
        </>
    );

}