import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Register3 from './Register3';
import LoadingButton from './LoadingButton';
import { API_BASE_URL } from '../config';

function Register2(props) {

    const navigate = useNavigate();

    const inputRefs = [
        null,
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef()
    ];

    const [verificationCode1, setVerificationCode1] = useState('');
    const [verificationCode2, setVerificationCode2] = useState('');
    const [verificationCode3, setVerificationCode3] = useState('');
    const [verificationCode4, setVerificationCode4] = useState('');
    const [verificationCode5, setVerificationCode5] = useState('');
    const [verificationCode6, setVerificationCode6] = useState('');
    const [continueToStep3, setContinueToStep3] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [actionMessage, setActionMessage] = useState(null);
    const [resendEmailCodeCounter, setResendEmailCodeCounter] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormLoading(true);
        const verificationCodeDigits = [
            verificationCode1,
            verificationCode2,
            verificationCode3,
            verificationCode4,
            verificationCode5,
            verificationCode6
        ];
        const verificationCode = verificationCodeDigits.join('');
        console.log(verificationCode);
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: verificationCode, email: props.data.email })
        };
        fetch(API_BASE_URL + 'register/2', requestOptions)
        .then(response => {
            if (response.ok) {
                setContinueToStep3(true);
            } else {
                setActionMessage('Ο κωδικός επιβεβαίωσης δεν είναι σωστός');
            }
            setFormLoading(false);
        })
        .catch(error => {
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
            setFormLoading(false);
        });
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (value.length < 2) {
        // Update the corresponding state variable
        switch (index) {
            case 1:
                setVerificationCode1(value);
                break;
            case 2:
            setVerificationCode2(value);
                break;
            case 3:
                setVerificationCode3(value);
                break;
            case 4:
                setVerificationCode4(value);
                break;
            case 5:
                setVerificationCode5(value);
                break;
            case 6:
                setVerificationCode6(value);
                break;
            default:
                break;
        }

        // Move focus to the next input field if it exists
        if (index < inputRefs.length - 1) {
            console.log(index + ' ' + inputRefs.length);
            const nextInputRef = inputRefs[index + 1].current;
            nextInputRef.focus();
            nextInputRef.select();
        }

        isValidForm();
    }
  };
    
  const resendEmailCode = () => {
    setResendEmailCodeCounter(20);
    setFormLoading(true);
    fetch(API_BASE_URL + 'register/2/resend-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: props.data.email })
    })
    .then(response => {
        if (response.ok) {
            setActionMessage('Στείλαμε νέο κωδικό στο email σου');
        } else {
            setActionMessage('Έλεγξε τη σύνδεσή σου στο διαδίκτυο και δοκίμασε πάλι');
        }
        setFormLoading(false);
    })
    .catch(error => {
        setActionMessage('Κάτι δεν λειτούργησε σωστά. Προσπάθησε ξανά!');
        setFormLoading(false);
    });
  } 

  const isValidForm = () => {
    return (
        verificationCode1 !== '' &&
        verificationCode2 !== '' &&
        verificationCode3 !== '' &&
        verificationCode4 !== '' &&
        verificationCode5 !== '' &&
        verificationCode6 !== ''
    );
  };

  useEffect(() => {
    let timer = null;

    if (resendEmailCodeCounter > 0) {
      timer = setTimeout(() => {
        setResendEmailCodeCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendEmailCodeCounter]);

    return (
        <>
            {continueToStep3 ? (
                <Register3 data={{
                    email: props.data.email,
                    password: props.data.password,
                }} />
            ) : (
                <div className="user_form_container">
                    {actionMessage ? (
                        <div className="message positive" onClick={() => setActionMessage(null)}>{actionMessage}</div>
                    ) : null}
                    <form onSubmit={handleSubmit} className='user_form'>
                        <label>
                            <span>Βάλε τον 6ψήφιο κωδικό επιβεβαίωσης που στείλαμε στο email σου:</span>
                            <div className="inputs_special">
                                <input 
                                    ref={inputRefs[1]}
                                    type="number" 
                                    value={verificationCode1} 
                                    maxLength="1" 
                                    onChange={(event) => handleInputChange(1, event)} 
                                    placeholder=" "

                                />
                                <input 
                                    ref={inputRefs[2]}
                                    type="number" 
                                    value={verificationCode2} 
                                    maxLength="1" 
                                    onChange={(event) => handleInputChange(2, event)} 
                                    placeholder=" "

                                />
                                <input 
                                    ref={inputRefs[3]}
                                    type="number" 
                                    value={verificationCode3} 
                                    maxLength="1" 
                                    onChange={(event) => handleInputChange(3, event)} 
                                    placeholder=" "

                                />
                                <input 
                                    ref={inputRefs[4]}
                                    type="number" 
                                    value={verificationCode4} 
                                    maxLength="1" 
                                    onChange={(event) => handleInputChange(4, event)} 
                                    placeholder=" "

                                />
                                <input 
                                    ref={inputRefs[5]}
                                    type="number" 
                                    value={verificationCode5} 
                                    maxLength="1" 
                                    onChange={(event) => handleInputChange(5, event)} 
                                    placeholder=" "

                                />
                                <input 
                                    ref={inputRefs[6]}
                                    type="number" 
                                    value={verificationCode6} 
                                    maxLength="1" 
                                    onChange={(event) => handleInputChange(6, event)} 
                                    placeholder=" " />
                            </div>
                        </label>
                        {formLoading === true ? (
                            <LoadingButton />
                        ) : (
                            <>
                                <button type="submit" className='button_cta' disabled={!isValidForm()}>Επιβεβαίωση</button>
                                <div 
                                    className={`button_secondary button_underlined ${resendEmailCodeCounter === 0 ? 'disabled' : ''}`} 
                                    onClick={() => {
                                        if(resendEmailCodeCounter === 0) {
                                            resendEmailCode()
                                        }
                                    }}
                                >
                                    Δεν έλαβες κωδικό? Επαναποστολή 
                                    {resendEmailCodeCounter !== 0 ? (
                                        <span> σε {resendEmailCodeCounter} δευ.</span>
                                    ) : (
                                        <span> τώρα</span>
                                    )}
                                </div>
                            </>
                        )}
                    </form>
                    <button className="button_secondary fixed_down" onClick={() => navigate('/login')}>
                        Έχεις ήδη λογαριασμό?
                        <br></br>
                        <u>Συνδέσου εδώ.</u>
                    </button>
                </div>
            )}
        </>
    );
}

export default Register2;