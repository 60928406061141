import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import IconBlock from './icons/IconBlock';
import IconFriendAdd from './icons/IconFriendAdd';
import IconFriendReject from './icons/IconFriendReject';
import IconReject from "./icons/IconReject";
import IconConfirm from './icons/IconConfirm';

import ActionPopup from './ActionPopup';
import BackButton from "./BackButton";
import LoadingScreen from "./LoadingScreen";
import LoadingScreenInner from "./LoadingScreenInner";
import { API_BASE_URL } from "../config";

function Profile() {

    const navigate = useNavigate();

    const { profile_type, profile_nickname } = useParams();

    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [loadingAction, setLoadingAction] = useState(false);
    const [actionPopup, setActionPopup] = useState(null);
    const [actionPopupAction, setActionPopupAction] = useState(false);
    const [friendBlockId, setFriendBlockId] = useState(null);
    const [friendRejectId, setFriendRejectId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Get stored bearer token
    const token = localStorage.getItem('adventure_map_bearer_token');

    const getProfileInfo = ( profileType, profileNickname, token ) => {
        // Get profile info
        setLoadingAction(true);
        fetch(API_BASE_URL + 'account/view', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                profile_type: profileType,
                profile_nickname: profileNickname,
            })
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            } else {
                throw new Error('Profile not found');
            }
        })
        .then(responseJson => {
            setProfileData(responseJson.profile);
            setLoading(false);
            setLoadingAction(false);
        })
        .catch(() => {
            setErrorMessage('Δεν βρήκαμε αυτό που αναζήτησες!');
            setLoading(false);
            setLoadingAction(false);
        });
    }

    useEffect(() => {
        getProfileInfo(profile_type, profile_nickname, token);
    }, []);

    // Add user as friend
    const friendRequest = (friend_id) => {
        setLoadingAction(true);
        fetch(API_BASE_URL + 'friends/request', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_to_befriend: friend_id }),
        })
        .then(response => {
            if(response.ok) {
                getProfileInfo(profile_type, profile_nickname, token);
            } else {
                setLoadingAction(false);
                throw new Error('Request not found');
            }
        })
        .catch(() => {
            setLoadingAction(false);
        });
    }

    // Accept user as friend
    const friendAccept = (friend_id) => {
        setLoadingAction(true);
        fetch(API_BASE_URL + 'friends/request/accept', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_to_accept: friend_id }),
        })
        .then(response => {
            if(response.ok) {
                getProfileInfo(profile_type, profile_nickname, token);
            } else {
                setLoadingAction(false);
                throw new Error('Request not found');
            }
        })
        .catch(() => {
            setLoadingAction(false);
        });
    }

    // Remove user from friend
    const friendReject = (friend_id) => {
        setLoadingAction(true);
        fetch(API_BASE_URL + 'friends/request/reject', {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ friend_to_delete: friend_id }),
        })
        .then(response => {
            if(response.ok) {
                getProfileInfo(profile_type, profile_nickname, token);
            } else {
                setLoadingAction(false);
                throw new Error('Request not found');
            }
        })
        .catch(() => {
            setLoadingAction(false);
        });
    }
    
    // Block user
    const friendBlock = (friend_id) => {
        setLoadingAction(true);
        fetch(API_BASE_URL + 'friends/block', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_to_block: friend_id }),
        })
        .then(response => {
            if(response.ok) {
                getProfileInfo(profile_type, profile_nickname, token);
            } else {
                setLoadingAction(false);
                throw new Error('Request not found');
            }
        })
        .catch(() => {
            setLoadingAction(false);
        });
    }
    
    // Unblock user
    const friendUnblock = (friend_id) => {
        setLoadingAction(true);
        fetch(API_BASE_URL + 'friends/unblock', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_to_unblock: friend_id }),
        })
        .then(response => response.json())
        .then(() => {
            setFriendBlockId(null);
            alert('Ο χρήστης ξεμπλοκαρίστηκε');
            getProfileInfo(profile_type, profile_nickname, token);
        })
        .catch(() => {
            setLoadingAction(false);
        });
    }

    if( loading !== false ) {
        return <LoadingScreen />
    }

    if( errorMessage !== null ) {
        return (
            <>
                <div className="loading-screen">{errorMessage}</div>
                <BackButton />
            </>
        );
    }

    return (
        <>
            <BackButton />
            <div className="screen content_top">
                <div className="screen-inner scrollable">
                    {loadingAction === true && (
                        <LoadingScreenInner />
                    )}
                    {profileData !== null && (
                        <>
                            <div className="profile-info">
                                {profileData.profile_image_url && (
                                    <img src={profileData.profile_image_url} className="user-profile-image" />
                                )}
                                <div className="spacer"></div>
                                {profileData.name && (
                                    <div className="user-name">
                                        {profileData.name}
                                    </div>
                                )}
                                {profileData.nickname && (
                                    <div className="user-nickname">
                                        @{profileData.nickname}
                                    </div>
                                )}
                                {profileData.bio && (
                                    <div className="user-bio">
                                        {profileData.bio}
                                    </div>
                                )}
                                <div className="spacer"></div>
                                {profileData.nickname && (
                                    <div className="profile-actions">
                                        {profileData.nickname && ( // If has nickname, they are a player
                                            <>
                                                {profileData.friend_request !== null ? (
                                                    profileData.friend_request.accepted_at === null ? (
                                                        <>
                                                            {profileData.friend_request.sent_request === profileData.id ? (
                                                                // If you are not friends, check if this user sent the request
                                                                <>
                                                                    <div className="button_with_icon button positive" onClick={() => friendAccept(profileData.id)}>
                                                                        <IconConfirm />
                                                                        Αποδοχή φίλου
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                profileData.is_blocked !== 1 && (
                                                                    // Request already sent icon
                                                                    <div className="button_with_icon positive">
                                                                        <IconConfirm />
                                                                        Έχει σταλεί αίτημα φιλίας
                                                                    </div>
                                                                )
                                                            )}
                                                            <div className="button_with_icon button negative" onClick={() => friendReject(profileData.id, profileData.name)}>
                                                                <IconReject />
                                                                Διαγραφή αιτήματος
                                                            </div>
                                                        </>
                                                    ) : (
                                                        // Already friends
                                                        <>
                                                            <div className="button_with_icon positive">
                                                                <IconConfirm />
                                                                Είστε φίλοι
                                                            </div>
                                                            <div className="button_with_icon button negative" onClick={() => friendReject(profileData.id, profileData.name)}>
                                                                <IconFriendReject />
                                                                Διαγραφή φίλου
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    profileData.is_blocked !== 1 && (
                                                        // If no friend request has been sent, show add friend button
                                                        <div className="button_with_icon button positive" onClick={() => friendRequest(profileData.id)}>
                                                            <IconFriendAdd />
                                                            Στείλε αίτημα φιλίας
                                                        </div>
                                                    )
                                                )}
                                                {profileData.is_blocked !== 1 ? (
                                                    <div className="button_with_icon button negative" onClick={() => friendBlock(profileData.id, profileData.name)}>
                                                        <IconBlock />
                                                        Μπλοκ
                                                    </div>
                                                ) : (
                                                    <div className="button_with_icon button positive" onClick={() => friendUnblock(profileData.id, profileData.name)}>
                                                        <IconBlock />
                                                        Αφαίρεση μπλοκ
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                                {profileData.description_short && (
                                    <div className="user-description-short">
                                        {profileData.description_short}
                                    </div>
                                )}
                                {profileData.description && (
                                    <div className="user-description">
                                        {profileData.description}
                                    </div>
                                )}
                                {(profileData.coupons_count !== undefined && profileData.giftcards_count !== undefined && profileData.events_count !== undefined) && (
                                    <>
                                        {(profileData.coupons_count > 0 || profileData.giftcards_count > 0 || profileData.events_count > 0) && (
                                            <>
                                                <div className="profile-place-buttons">
                                                    {parseInt(profileData.coupons_count, 10) > 0 && (
                                                    <div className="profile-place-button">
                                                        Κουπόνια
                                                    </div>
                                                    )}
                                                    {parseInt(profileData.giftcards_count, 10) > 0 && (
                                                    <div className="profile-place-button">
                                                        Δωροεπιταγές
                                                    </div>
                                                    )}
                                                    {parseInt(profileData.events_count, 10) > 0 && (
                                                        <div className="profile-place-button">
                                                            Events
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="bottom-spacer"></div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
export default Profile;