import AppLogo from "./AppLogo";

export default function AppHeader() {

    return (
        <header>
            <AppLogo />
            <h1>Adventure Map</h1>
        </header>
    );

}