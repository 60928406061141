import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconBack from './icons/IconBack';

function BackButton() {

    const navigate = useNavigate();

    return (
        <div className="close_button" onClick={() => navigate(-1)}>
            <IconBack />
        </div>
    );
}
export default BackButton;