import BackButton from "./BackButton";
import { useNavigate } from 'react-router-dom';

export default function Wallet() {

    const navigate = useNavigate();

    return (
        <>
            <div className="screen">
                <div className="screen-inner gap-normal">
                    <h2 className="section-title">Πορτοφόλι</h2>
                    <h3>Εδώ μπορείς να δεις τα αντικείμενα που σου ανήκουν!</h3>
                    <div className="items_list center">
                        <button className="button button_cta" onClick={() => navigate('/wallet/coupons')}>
                            Κουπόνια
                        </button>
                        <button className="button button_cta" onClick={() => navigate('/wallet/giftcards')}>
                            Δωροεπιταγές
                        </button>
                        <button className="button button_cta" onClick={() => navigate('/wallet/events')}>
                            Συμμετοχές σε Events
                        </button>
                    </div>
                </div>
                <BackButton />
            </div>
        </>
    );

}