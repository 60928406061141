import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import IconFriendReject from './icons/IconFriendReject';
import IconFriendAdd from './icons/IconFriendAdd';
import IconBlock from './icons/IconBlock';
import BackButton from './BackButton';
import LoadingScreen from './LoadingScreen';
import { API_BASE_URL } from '../config';

function Friends({ changeCurrentPage, setProfileToView }) {

    const navigate = useNavigate();

    const [friends, setFriends] = useState(null);
    const [friendsCurrentPage, setFriendsCurrentPage] = useState(1);
    const [friendRequests, setFriendRequests] = useState(null);
    const [showFriendRequests, setShowFriendRequests] = useState(false);
    const [friendRequestsCurrentPage, setFriendRequestsCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const fixedTopRef = useRef(null);

    // Get stored bearer token
    const token = localStorage.getItem('adventure_map_bearer_token');

    const fetchFriends = (page) => {
        // Get friends
        fetch(API_BASE_URL + 'friends?page=' + page, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            } else {
                navigate('/login');
            }
        }).then(responseJson => {
            setFriends(responseJson.friends);
            setLoading(false);
        }).catch(() => { // If error, show it
            setErrorMessage('Προέκυψε κάποιο σφάλμα, δοκίμασε σε λίγο πάλι!');
            setLoading(false);
        });
    } 

    const fetchFriendRequests = (page) => {
        // Get friend requests
        fetch(API_BASE_URL + 'friends/requests?page=' + page, {
            method: 'GET',
            headers: {
            'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            } else {
                navigate('/login');
            }
        }).then(responseJson => {
            setFriendRequests(responseJson.friend_requests);
            setLoading(false);
        }).catch(() => { // If error, show it
            setErrorMessage('Προέκυψε κάποιο σφάλμα, δοκίμασε σε λίγο πάλι!');
            setLoading(false);
        });
    }

    const friendAction = (action) => {
        // Get friend requests
        fetch(API_BASE_URL + 'friends/request/' + action, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            // body: JSON.stringify({
            //     action === 
            // })
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            } else {
                navigate('/login');
            }
        }).then(responseJson => {
            setFriendRequests(responseJson.friend_requests);
            setLoading(false);
        }).catch(() => { // If error, show it
            setErrorMessage('Προέκυψε κάποιο σφάλμα, δοκίμασε σε λίγο πάλι!');
            setLoading(false);
        });
    }

    useEffect(() => {
        if (fixedTopRef.current) {
            const fixedTopHeight = fixedTopRef.current.offsetHeight;
            const fixedTopSection = document.querySelector('.friends-list');
            if (fixedTopSection) {
                fixedTopSection.style.height = `calc(100vh - ${fixedTopHeight}px - 64px)`;
                fixedTopSection.style.top = `calc(${fixedTopHeight}px)`;
                fixedTopSection.style.left = `0`;
                fixedTopSection.style.transform = `none`;
            }
        }
    });

    useEffect(() => {
        fetchFriends(1);
        fetchFriendRequests(1);
    }, [token]);

    if( errorMessage !== null ) {
        return (
            <>
                <div className="loading-screen">
                    {errorMessage}
                </div>
                <BackButton />
            </>
        );
    }

    if( loading || friends === null || friendRequests === null ) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className="screen_sections_buttons fixed_top" ref={fixedTopRef}>
                <button className={`button_secondary${showFriendRequests ? '' : ' active'}`} onClick={() => setShowFriendRequests(false)}>Φίλοι ({friends.length})</button>
                <button className={`button_secondary${showFriendRequests ? ' active' : ''}`} onClick={() => setShowFriendRequests(true)}>Αιτήματα ({friendRequests.length})</button>
            </div>
            <div className="screen scrollable content_top friends-list">
                <div className="screen-inner gap-normal no-padding-block">
                    <div className="items_list">
                        {showFriendRequests !== true ? (
                            friends.length > 0 ? (
                                friends.map((friend) => (
                                    <div key={friend.nickname} className="list_item" onClick={() => navigate(`/profile/user/${friend.nickname}`)}>
                                        {friend.profile_image_catalog && (
                                            <div>
                                                <img src={friend.profile_image_catalog} alt="profile " />
                                            </div>
                                        )}
                                        <div>
                                            <div>{friend.name}</div>
                                            {friend.nickname && <div>@{friend.nickname}</div>}
                                        </div>
                                        {/* <div className="list_item_actions">
                                            <div className="button_only_icon button negative" onClick={() => friendAction('delete', friend.id)}>
                                                <IconFriendReject />
                                            </div>
                                            <div className="button_only_icon button negative" onClick={() => friendAction('block', friend.id)}>
                                                <IconBlock />
                                            </div>
                                        </div> */}
                                    </div>
                                ))
                                ) : (
                                    <div className="no_results_found">Δεν έχεις συνδεθεί με κάποιον. Βρες φίλους στην αναζήτηση!</div>
                            )
                            ) : (
                            friendRequests.length > 0 ? (
                                friendRequests.map((friendRequest) => (
                                    <div key={friendRequest.id} className="list_item" onClick={() => navigate(`/profile/user/${friendRequest.nickname}`)}>
                                        {friendRequest.profile_image_url_64x64 && (
                                            <div>
                                                <img src={friendRequest.profile_image_url_64x64} alt="profile " />
                                            </div>
                                        )}
                                        <div>
                                            <div>{friendRequest.name}</div>
                                            {friendRequest.nickname && <div>@{friendRequest.nickname}</div>}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="no_results_found">Δεν έχεις εκκρεμή αιτήματα φιλίας.</div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className="bottom-spacer"></div>
            <BackButton />
        </>
    );
}

export default Friends;